import {ContactModel} from './contact';
import {RoleModel} from './role';

export enum EDashboardType {
  rsm = 1,
  all
}
export enum EOfferType  {
  none = 0,
  all,
  bu,
  customer,
}
export class ErpRoles {
  public admin: boolean = false;
  public materials: boolean = false;
  public readOnlyMaterials: boolean = false;
  public nrka: boolean = false;
  public readOnlyNrka: boolean = false;
  public nrkg: boolean = false;
  public readOnlyNrkg: boolean = false;
  public products: boolean = false;
  public readOnlyProducts: boolean = false;
  public reports: boolean = false;
  public readOnlyReports: boolean = true;
  public customerReport: boolean = false;
  public dashboard: boolean = false;
  public dashboardType: EDashboardType = EDashboardType.rsm;
  public noCrm: boolean = false;
  public availability: boolean = false;
  public openorders: boolean = false;
  public warehousing: boolean = false;
  public openinvoices: boolean = false;
  public loginAdmin: boolean = false;
  public offers: EOfferType = EOfferType.none;
  public business: boolean = false;
  public testServerAccess: boolean = false;
  public salesReport: boolean = false;
}

export class UserModel {
  public _id: string;
  public name: string;
  public username: string;
  public admin: boolean;
  public active: boolean;
  public hash?: string;
  public contact?: ContactModel;
  public roles: RoleModel[];
  public erpRoles: ErpRoles;
  public short?: string;
}
