import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nrkKeyValue'
})
export class NrkKeyValuePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || typeof value !== 'object') {
      return [];
    }
    return Object.keys(value).map(key => ({ key, value: value[key] }));  }

}
