import { Component, Input, isDevMode, OnDestroy, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import {ApiService} from '../../../@core/service/api.service';
import {UserModel} from '../../../@models';
import { AuthGuardService } from '../../../@core/service/auth-guard.service';
import { TabCoordinatorService } from '../../../@core/service/tab-coordinator.service';
import { SharedDataService } from '../../../@core/service/shared-data.service';
import { mongoId } from '../../../@core/common/global';
import { WarehousingModel } from '../../../@models/warehousing';
import { Router } from '@angular/router';
import { EventService } from '../../../@core/service/eventService';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {


  @Input() position = 'normal';

  user: UserModel;

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  developement = isDevMode();

  expiryTime = '';

  static unreadMessages: WarehousingModel[] = [];

  headerComponent = HeaderComponent;

  subscriptions: Subscription[] = [];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private api: ApiService,
    private data: SharedDataService,
    private router: Router,
    public auth: AuthGuardService,
    private event: EventService,
    protected tabCoordiantorService: TabCoordinatorService,
    private analyticsService: AnalyticsService) {
  }

  async ngOnInit() {
    // console.log('init header', this.auth.user);
    await this.data.loadWarehousing();

    const readMessages = () => {
      HeaderComponent.unreadMessages = this.data.warehousingMessages.filter(f => 
        mongoId(f.recipient) === mongoId(this.auth.user) 
        && !f.isReadBy.find(rb => mongoId(rb.user) === mongoId(this.auth.user))
        && !f.deleted
      );

      console.log('unread messages', HeaderComponent.unreadMessages);

      this.subscriptions = [
        this.data.$expiryTime.subscribe(s => this.expiryTime = s)
      ]
    }
    readMessages();

    setInterval(async () => {
      await this.data.reloadWarehousing();
      readMessages();
      // console.log('unread messages', unreadMessages, this.data.warehousingMessages, this.data.warehousingMessages.filter(f => mongoId(f.recipient) === mongoId(this.auth.user)));
    }, 10000);

    this.api.currentUser.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  popMessage() {
    const message = HeaderComponent.unreadMessages[0];
    console.log('postMessage', message.code);
    if (/^OCNRK/.test(message.code)) {
      this.router.navigate(['/pages/openorders'], { queryParams: { message: mongoId(message) } });
    } else if (/^DNNRK/.test(message.code)) {
      this.router.navigate(['/pages/warehousing'], { queryParams: { message: mongoId(message) } });
    } else if (/^INNRK/.test(message.code)) {
      this.router.navigate(['/pages/openinvoices'], { queryParams: { message: mongoId(message) } });
    }
    this.event.emitShowMessage(message);
  }
}
