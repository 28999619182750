import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ngxRound' })
export class RoundPipe implements PipeTransform {

  transform(input: number, decimals: number = 0, toString: boolean = false): number {
    const ret = Math.round(+input * Math.pow(10, +decimals)) / Math.pow(10, +decimals);
    // console.log('ngxRound', input, decimals, ret);
    return toString ? input?.toFixed(decimals) as any : ret;
  }
}
